import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../../../components/Layout';
import Seo from '../../../components/Seo';
import FreeTrialSignup from '../../../components/FreeTrialSignup';

import './style.scss';

const Page = () => {
    return (
        <Layout navTheme="light">
            <Seo
                title="New release updates | GlassHive"
                description="At GlassHive, we are continuously making improvements to the platform to enhance user experience and functionality. Check out the newest features!"
            />
            <div className="release-notes-page">
                <Container className="container-1">
                    <Row className="center">
                        <Col className="centered-text">
                            <h1 className="white-text">
                                GlassHive new release updates
                            </h1>
                            <p className="hero-subtext white-text">
                                September 2022
                            </p>
                        </Col>
                    </Row>
                    <img
                        className="white-divider"
                        src={require('../../../images/features/lead-scrubbing/white-divider.svg')}
                        alt="white divider"
                    />
                </Container>
                <Container className="container-2">
                    <Row>
                        <Col className="three-fifth video-column">
                            <iframe
                                width="560"
                                height="315"
                                src="https://www.youtube.com/embed/n_b3Peo80_Y"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                            ></iframe>
                        </Col>
                        <Col className="two-fifth">
                            <h4 className="centered-mobile">
                                The latest updates from GlassHive
                            </h4>
                            <p>
                                <b>We're back</b> with another Glasshive release
                                notes video. Here we'll discuss all the updates,
                                features, or quality of life improvements we
                                have made in the Hive. Let's get into it!
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className="container-3">
                    <Row>
                        <Col className="two-fifth">
                            <h4 className="centered-mobile">New features</h4>
                            <ul>
                                <li>
                                    Partner users can now create and add forms
                                    to landing pages
                                </li>
                                <li>
                                    Org users now have the ability to create
                                    landing page templates{' '}
                                </li>
                                <li>
                                    Partner users can now use subscription
                                    landing page templates
                                </li>
                                <li>
                                    Users can now receive in-app notifications,
                                    as well as customize their notification
                                    preferences
                                </li>
                                <li>
                                    Companies can now have locations attached to
                                    them
                                </li>
                                <li>Companies can now be marked as accounts</li>
                            </ul>

                            <h4 className="centered-mobile">
                                Updates & Improvements
                            </h4>
                            <ul>
                                <li>
                                    Changed default for form submission
                                    notifications to be graphical
                                </li>
                                <li>
                                    Updated Landing Page domain widget to state
                                    GlassHive instead of Glasshive
                                </li>
                                <li>
                                    Wistia Integration was removed from the
                                    integrations page
                                </li>
                                <li>
                                    Vendors can now view number of lists and
                                    campaigns their partners have
                                </li>
                                <li>
                                    Companies table can now be sorted by
                                    temperature
                                </li>
                                <li>Updated O365 step 2 integration video</li>
                                <li>
                                    Users can now add files and notes to
                                    Companies
                                </li>
                                <li>
                                    Landing pages title was updated under the
                                    vendor menu
                                </li>
                                <li>
                                    New users need to agree to TOS and Privacy
                                    terms before being allowed to sign up
                                </li>
                                <li>
                                    Updated company tables to do bulk assigning
                                    to companies, rather than assigning to the
                                    companies' contacts
                                </li>
                                <li>Added Accounts to Sales Nav</li>
                            </ul>

                            <h4 className="centered-mobile">Bug fixes</h4>
                            <ul>
                                <li>
                                    Fixed a bug where CW integration management
                                    info cards were not displaying the full
                                    content
                                </li>
                                <li>
                                    Fixed a bug where new lines were ignored on
                                    multiline custom fields
                                </li>
                                <li>
                                    Fixed a bug when attempting to edit a
                                    contact from the Vendor Partner Leads
                                </li>
                                <li>
                                    Fixed a bug where creating a new opportunity
                                    throws an error on the contact details page
                                </li>
                                <li>
                                    Fixed bugs where "LPs Using It" section for
                                    Forms wouldn't tie to correct LPs
                                </li>
                                <li>
                                    Fixed a bug where the Journey Email activity
                                    under the contact details page, was
                                    displaying the contacts email address
                                    instead of the senders email address in the
                                    "From:" section
                                </li>
                                <li>
                                    Fixed a bug where the plain text email
                                    templates widget was throwing an error when
                                    searching for shared templates
                                </li>
                                <li>
                                    Fixed a bug where the thumbnail preview was
                                    not displaying the full content under the
                                    journey details page
                                </li>
                                <li>
                                    Fixed bug where Salesperson dashboard used
                                    lifetime values for opportunity and win
                                    quotas
                                </li>
                                <li>
                                    Fixed bug where Salesperson Dashboard wasn't
                                    opening activities correctly
                                </li>
                                <li>
                                    Fixed bug where Qualified Leads showed the
                                    same data as My Qualified Leads if you are a
                                    Partner salesperson
                                </li>
                            </ul>
                        </Col>
                        <Col className="three-fifth form-column">
                            <FreeTrialSignup titleText="Sign up for a free account today!" />
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};

export default Page;
